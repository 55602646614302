import ImageGallery from "./../components/ImageGallery";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { type } from "@testing-library/user-event/dist/type";
import { Button, Col } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';

const ImageManager = ({ event }) => {
  const [images, setImages] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const navigate = useNavigate();


  const fetchImages = async () => {
    setIsUploading(true);
    try {
      const response = await axios.get(
        process.env.REACT_APP_MODEL_ENDPOINT + `/images?eid=${event.id}`
      );

      if (typeof response.data !== "object") {
        console.log("Error fetching images:", response.data);
        return;
      }

      setImages(response.data);
    } catch (error) {
      console.error("Error fetching images:", error);
    } finally {
      setIsUploading(false);
    }
  };


  const handleRedirect = () => {
    navigate(`/${event.id}/admin/upload`);
  };

  // retrieve all the images uploaded for the event on load
  useEffect(() => {
    fetchImages();
  }, [event.id]);

  return (
    /* Show the event name */
    event && (
      <>
        <div className="container border rounded p-2 m-1">
          {/* spinner when is uploading */}
          {isUploading && (
            <div className="text-center">
              <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          )}

          {/* Upload */}
          <div className="container m-1">
            <Col>
              {images.length > 0 && (
                <Col className="m-2 text-center"><strong>{images.length} Images available</strong></Col>
              )}

              <Col className="text-center">
              {/* redirection to /admin/upload */}
                <Button className="mb-3" variant="success" onClick={handleRedirect}>
                  {images.length > 0
                    ? "Upload More Images"
                    : "Started Uploading Images"}
                </Button>
              </Col>
            </Col>
          </div>

          {/* show gallery is images found, else show now images found */}
          {images.length > 0 ? (
            /* Image Gallery component */
            <>
              <h3 className="text-center mt-2">My Images</h3>
              <ImageGallery
                event_id={event.id}
                images={images}
                show_delete={true}
                setImages={setImages}
              />
            </>
          ) : (
            <h4 className="text-center mt-5">No Images Found</h4>
          )}
        </div>
      </>
    )
  );
};
export default ImageManager;
