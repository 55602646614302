import React, { useState } from "react";
import ImageManager from "./ImageManager";

const AdminPanel = ({ event }) => {
  const [images, setImages] = useState([]);

  return (
    /* Show the event name */
    event && (
      <>
        {/* show gallery is images found, else show now images found */}
        <ImageManager event={event} images={images} />
      </>
    )
  );
};
export default AdminPanel;
