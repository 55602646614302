// src/App.js
import React, { useState } from "react";
import AdminPanel from "./pages/AdminPanel";
import UserPanel from "./pages/UserPanel";
import UploadImg from "./pages/UploadImg";
import Header from "./components/Header";

import { Container } from "react-bootstrap";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

function App() {
  const [event, setEvent] = useState({});
  
  return (
    <Container className="mt-4">
    {/* retrive the event Name from /check_eid endpoint */}
    <Router>
        <Routes>
          <Route
            path="/:event_id"
            element={
              <>
                <Header setEvent={setEvent} />
                <UserPanel event={event} />
                </>
            }
          />

           {/* Route for the admin panel, e.g., http://localhost:3000/1/admin */}
           <Route
            path="/:event_id/admin"
            element={
              <>
                <Header setEvent={setEvent} />
                <AdminPanel event={event} />
                </>
            }
          />

           {/* Route for the admin panel, e.g., http://localhost:3000/1/admin/upload */}
           <Route
            path="/:event_id/admin/upload"
            element={
              <>
                <Header setEvent={setEvent} />
                <UploadImg event={event} />
                </>
            }
          />


        </Routes>
      </Router>
    </Container>
  );
}

export default App;
