import React, { useEffect, useState } from "react";
import axios from "axios";
import { faProjectDiagram } from "@fortawesome/free-solid-svg-icons/faProjectDiagram";
import { useParams, useLocation } from "react-router-dom";

const Header = ({ setEvent }) => {
    const [event, setEventState] = useState(null);
    const { event_id } = useParams();
    const location = useLocation(); // Get the current location


     // Check if the current route contains "admin"
    const isAdminRoute = location.pathname.includes("admin");

    const updateEvent = (eventData) => {
        setEvent(eventData); // Update the parent state
        setEventState(eventData); // Update the local state
    };


    useEffect(() => {
        const fetchEventName = async () => {
            try {
                // Use the environment variable here
                const response = await axios.get( process.env.REACT_APP_MODEL_ENDPOINT + `/check_eid?eid=${event_id}`)
                if (response.data && response.data.success) {
                    updateEvent(response.data.event);
                } else {
                    console.error("invalid event id:", response.data);
                    updateEvent(null);
                }
            } catch (error) {
                updateEvent(null);
            }
        };
    
        fetchEventName();
    }, [event_id]);

    return (
        <>
        <h2 className="text-center mb-4">
            {/* if route is {id}/admin then add admin */}
            {isAdminRoute ? "Admin Panel: " : ""}

            {event ? event.name : "Event Not Found"}
        </h2>
        </>
    );
};

export default Header;
