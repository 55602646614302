import React, { useState, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { Button, ProgressBar, Form } from "react-bootstrap";
import axios from "axios";

const UploadImg = ({ event }) => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [totalSize, setTotalSize] = useState(0);

  const onDrop = useCallback((acceptedFiles) => {
    const totalSizeInBytes = acceptedFiles.reduce(
      (acc, file) => acc + file.size,
      0
    );
    setSelectedFiles(acceptedFiles);
    setTotalSize((totalSizeInBytes / (1024 * 1024)).toFixed(2)); // Convert bytes to MB
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'image/jpeg': ['.jpg', '.jpeg'],
      'image/png': ['.png'],
    },
    multiple: true,
  });
  

  const handleFileSelect = (event) => {
    const files = event.target.files;
    const totalSizeInBytes = Array.from(files).reduce(
      (acc, file) => acc + file.size,
      0
    );
    setSelectedFiles(files);
    setTotalSize((totalSizeInBytes / (1024 * 1024)).toFixed(2));
  };

  const handleUpload = async () => {
    if (selectedFiles.length === 0) {
      alert("Please select one or more images to upload.");
      return;
    }

    const formData = new FormData();
    Array.from(selectedFiles).forEach((file) => {
      formData.append("images", file);
    });

    const uploadId = `upload_${Date.now()}`; // Generate a unique ID for this upload

     // Polling function to get server-side progress
     const pollProgress = setInterval(async () => {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_MODEL_ENDPOINT}/upload/progress?upload_id=${uploadId}`
        );
        setUploadProgress(data.progress);
  
        if (data.progress >= 100) {
          clearInterval(pollProgress);
        }
      } catch (error) {
        console.error("Error fetching upload progress:", error);
        clearInterval(pollProgress);
      }
    }, 1000); // Poll every 500 ms
  
    try {
      // Upload the image with a unique upload ID
      const response = await axios.post(
        `${process.env.REACT_APP_MODEL_ENDPOINT}/images?eid=${event.id}&upload_id=${uploadId}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
  
      if (response.status === 200) {
        setSelectedFiles([]);
        setUploadProgress(0);
        setTotalSize(0);
        alert("Images uploaded successfully!");
      } else {
        alert("Failed to upload images.");
      }
    } catch (error) {
      console.error("Error uploading images:", error);
      alert("An error occurred while uploading images.");
      // setUploadProgress(0);
    } finally {
      clearInterval(pollProgress);
    }
  };

  return (
    <div className="upload-container">
      <h2>Upload Images</h2>

      {/* Drag and Drop Zone */}
      <div
        {...getRootProps()}
        className={`dropzone p-3 mb-3 border rounded bg-light ${
          isDragActive ? "border-primary" : "border-secondary"
        }`}
        style={{
          borderStyle: "dashed",
          cursor: "pointer",
          height: "400px",
          height: "400px",
          display: "flex",
          "flex-direction": "column",
          "justify-content": "center",
          "align-items": "center"
        }}
      >
        <input {...getInputProps()} />
        {isDragActive ? (
          <p>Drop the files here...</p>
        ) : (
          <p>Drag & drop some images here, or click to select files (PNG, JPEG, JPG)</p>
        )}
      </div>

      {selectedFiles.length > 0 && (
        <div className="mb-3">
          <p>
            Total size: {totalSize} MB ({selectedFiles.length} file
            {selectedFiles.length > 1 ? "s" : ""})
          </p>
        </div>
      )}

      {uploadProgress > 0 && (
        <ProgressBar
          now={uploadProgress}
          label={`${uploadProgress}%`}
          className="mb-3"
        />
      )}

      <Button
        variant="success"
        onClick={handleUpload}
        disabled={selectedFiles.length === 0 || uploadProgress > 0}
      >
        {uploadProgress > 0 ? "Uploading..." : "Upload Selected Images"}
      </Button>
    </div>
  );
};

export default UploadImg;
